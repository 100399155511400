import $ from 'jquery';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import Sortable from 'sortablejs';
import './arp-photo-upload.css';

import {inject, bindable, customElement, Container} from 'aurelia-framework';

import {WebApi} from '../WebApi';
import {UploadResource} from '../UploadResource';
import {ArpImage} from "../ArpImage";
import {ArpLogger, ArpLogManager} from "..";
import {IPhotoUpload} from "@sparkie/shared-model/src/model/common/IPhotoUpload";
import { find, remove } from 'lodash';

@customElement('arp-photo-upload')
@inject(Element)
export class ArpPhotoUploadCustomElement {

    @bindable id;
    @bindable photos: Array<IPhotoUpload>;
    @bindable s3bucket;
    private element: HTMLElement;
    private webApi: WebApi;
    private uploaded: Array<IPhotoUpload> = [];
    private removed: Array<IPhotoUpload> = [];
    private logger: ArpLogger;
    private dropzoneOptions: any;
    private dropzone: any;
    private readonly thumbnailWidth = 120;
    private readonly thumbnailHeight = 120;

    constructor(element) {
        this.element = element;
        this.webApi = Container.instance.get(WebApi);

        this.logger = ArpLogManager.getLogger("arp-photo-upload");
    }

    attached() {
        this.dropzoneOptions = {
            clickable: true,
            dictDefaultMessage: "Drop files or click here to upload",
            method: "put",
            uploadMultiple: false,
            maxFilesize: 20,                // MB
            createImageThumbnails: true,
            addRemoveLinks: true,
            url: (files) => this.getUrl(files),
            accept: (file, done) => this.accept(file, done),
            sending: (file, xhr, formData) => this.sending(file, xhr, formData),
            complete: (file) => this.complete(file)
        };

        this.dropzone = new Dropzone(this.element.children[0] as HTMLElement, this.dropzoneOptions);

        this.dropzone.processPhoto = (file, callback) => this.createThumbnail(file, callback);
        this.dropzone.createThumbnailFromUrl = (file, imageUrl, callback, crossOrigin) => this.createThumbnailFromUrl(file, imageUrl, callback, crossOrigin);

        for (let file of this.photos) {
            this.addExisting(file);
        }

        this.dropzone.on("removedfile", (file) => this.removedfile(file));

        const sortableOptions: Sortable.Options = {
            // WTF? The "indexes" are 1 based?
            onEnd: (event) => this.onMoveFile(event.oldIndex - 1, event.newIndex - 1)
        }

        Sortable.create(this.element.children[0] as HTMLElement, sortableOptions);

        this.logger.debug(`attached`);
    }

    addExisting(existingFile) {
        // Create the mock file:
        let mockFile = {
            url: existingFile.url,
            name: existingFile.filename,
            size: 12345
        };

        // Call the default addedfile event handler
        this.dropzone.emit("addedfile", mockFile);

        let thumbnailUrl = `${this.s3bucket}/${existingFile.url}`;
        this.dropzone.createThumbnailFromUrl(mockFile, thumbnailUrl, () => {
            // Make sure that there is no progress bar, etc...
            this.dropzone.emit("complete", mockFile);
        }, 'anonymous');

        // If you use the maxFiles option, make sure you adjust it to the
        // correct amount:
        //var existingFileCount = 1; // The number of files already uploaded
        //this.dropzone.options.maxFiles = myDropzone.options.maxFiles - existingFileCount;
    }

    async accept(file, done) {

        this.logger.debug(`accept ${JSON.stringify(file)}`);

        // `/types/file/action/sign?file_name=${file.name}&file_type=${file.type}&bucket=private&id=${this.id}`;
        let imageResource = new UploadResource()
            .withAction('sign')
            .withQueryParameter('id', this.id)
            .withQueryParameter('file_name', encodeURIComponent(file.name))
            .withQueryParameter('file_type', file.type)
            .withQueryParameter('bucket', 'public');

        try {
            // Load the image from file
            file.arpImage = await ArpImage.loadFile(file);

            // Upload thumbnails
            file.arpSmallThumbnailFilePath = await this.createAndUploadThumbnail(file, file.arpImage, 60, 60);
            file.arpMediumThumbnailFilePath = await this.createAndUploadThumbnail(file, file.arpImage, 200, 200);

            this.logger.info(`uploaded all thumbnails`);

            // Upload original
            let parsedResponse = await this.webApi.postJSON(imageResource);

            file.custom_status = 'ready';
            file.signedUrl = parsedResponse.signedUrl;
            file.uploadFilePath = parsedResponse.uploadFilePath;

            $(file.previewTemplate).addClass('uploading');

            done();
        } catch (err) {

            // If you pass an error message, the file is rejected, and the error message will be displayed.
            done(err);
        }
    }

    async createAndUploadThumbnail(file: any, image: ArpImage, width: number, height: number) : Promise<string> {

        let thumbnailFileName = "";
        let terms = file.name.split('.');

        if (terms.length >= 2) {
            let nameIndex = terms.length - 2;
            terms[nameIndex] = `${terms[nameIndex]}_thumb${width}`;
            thumbnailFileName = terms.join('.');
        } else {
            thumbnailFileName = `${file.name}_thumb${width}`;
        }

        // `/types/file/action/sign?file_name=${file.name}&file_type=${file.type}&bucket=private&id=${this.id}`;
        let thumbResource = new UploadResource()
            .withAction('sign')
            .withQueryParameter('id', this.id)
            .withQueryParameter('file_name', encodeURIComponent(thumbnailFileName))
            .withQueryParameter('file_type', file.type)
            .withQueryParameter('bucket', 'public');

        this.logger.info(`uploading ${thumbnailFileName}`);

        // Create the thumbnail
        let thumbnail: ArpImage = await image.createThumbnail(width, height);

        // Ask the back end for a signed url for this thumbnail
        let thumbResponse = await this.webApi.postJSON(thumbResource);
        let uploadFilePath = thumbResponse.uploadFilePath;

        // Now upload the thumbnail directly to Amazon S3 using the preauthenticated url
        await thumbnail.send(thumbResponse.signedUrl, file.type);

        this.logger.info(`uploaded ${thumbnailFileName}`);
        return Promise.resolve(uploadFilePath);
    }

    getUrl(files: Array<any>) {
        if (files.length > 1) {
            this.logger.error(`can't handle multiple file upload! ${JSON.stringify(files)}`);
        }
        // Is this always a single file?
        return files[0].signedUrl;
    }

    sending(file: File, xhr, formData) {
        // Dropzone calls xhr.send, but we need to override the call to send the File without formData
        let _send = xhr.send;
        xhr.send = function() {

            // Note a File derives from Blob!
            _send.call(xhr, file);
        };
    }

    complete(file) {
        if (file._removeLink) {
            file._removeLink.textContent = this.dropzone.options.dictRemoveFile;
        }

        if (file.previewElement) {
            file.previewElement.classList.add("dz-complete");
        }

        if (!find(this.photos, (photo: any) => file.url === photo.url)) {
            let fileEntity = this.createFileEntity(file);
            this.uploaded.push(fileEntity);
            this.photos.push(fileEntity);
            this.logger.debug(`Upload complete for ${JSON.stringify(fileEntity)}`);
        }
    }

    onMoveFile(oldIndex: number, newIndex: number) {
        array_move(this.photos, oldIndex, newIndex);
        this.logger.debug(`Moved file from ${oldIndex} to ${newIndex}\n${JSON.stringify(this.photos)}`);
    }

    removedfile(file) {
        this.logger.debug(`remove ${JSON.stringify(file)}`);

        let removed = remove(this.photos, (photo) => {
            this.logger.debug(`remove: ${JSON.stringify(photo)}`);
            return photo.url === file.uploadFilePath || photo.url === file.url
        });

        for (let fileEntity of removed) {
            this.removed.push(fileEntity);
        }
    }

    async createThumbnail(file, callback) {

        let fullImage: ArpImage = await ArpImage.loadFile(file);
        let thumbnailImage: ArpImage = await fullImage.createThumbnail(this.thumbnailWidth, this.thumbnailHeight);

        // Load the thumbnail into the imageElement for the file
        this.loadThumbnail(file, thumbnailImage);

        if (callback) {
            callback();
        }
    }

    async createThumbnailFromUrl(file, imageUrl, callback, crossOrigin?) {

        let fullImage: ArpImage = await ArpImage.loadUrl(imageUrl, crossOrigin);
        let thumbnailImage: ArpImage = await fullImage.createThumbnail(this.thumbnailWidth, this.thumbnailHeight);

        // Load the thumbnail into the imageElement for the file
        this.loadThumbnail(file, thumbnailImage);

        if (callback) {
            callback();
        }
    }

    loadThumbnail(file: any, image: ArpImage) {

        let imageElement: HTMLImageElement = file.previewElement.querySelectorAll("[data-dz-thumbnail]")[0] as HTMLImageElement;
        file.previewElement.classList.remove("dz-file-preview");

        imageElement.onload = () => {
            image.revokeObjectURL();
            file.previewElement.classList.add("dz-image-preview");
        };

        imageElement.alt = file.name;
        imageElement.src = image.createObjectURL();
    }

    thumbnail(file: any, dataUrl: string) {
        let thumbnailElement,
            _i,
            _len,
            _ref;
        if (file.previewElement) {
            file.previewElement.classList.remove("dz-file-preview");
            _ref = file.previewElement.querySelectorAll("[data-dz-thumbnail]");
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                thumbnailElement = _ref[_i];
                thumbnailElement.alt = file.name;
                thumbnailElement.src = dataUrl;
            }
            return setTimeout(((function(_this) {
                return function() {
                    return file.previewElement.classList.add("dz-image-preview");
                };
            })(this)), 1);
        }

        return null;
    }

    createFileEntity(file) : IPhotoUpload {
        return {
            url: file.uploadFilePath,
            smallThumbnailUrl: file.arpSmallThumbnailFilePath,
            mediumThumbnailUrl: file.arpMediumThumbnailFilePath,
            filename: file.name,
            title: "",
            description: "",
            uploaded: new Date()
        };
    }

    /**
     * Use Cases:
     *      Add FileA, Save->No Op, uploaded=FileA
     *      Add FileA, Remove FileA, Save->Delete FileA, uploaded=FileA, removed=FileA
     *      Add FileA, Remove FileB, Save->Delete FileB, uploaded=FileA, removed=FileB
     *
     * @returns {Promise<any>}
     */
    save() : Promise<any> {
        // Files that we removed can be removed from the server
        let ps = [];
        for (let fileEntity of this.removed) {
            // If there is a file entity we can
            ps.push(this.removeFromServer(fileEntity.url));
            ps.push(this.removeFromServer(fileEntity.smallThumbnailUrl));
            ps.push(this.removeFromServer(fileEntity.mediumThumbnailUrl));
        }

        return Promise.all(ps);
    }

    /**
     * Use Cases:
     *      Add FileA, Cancel->Delete FileA, uploaded=FileA
     *      Add FileA, Remove FileA, Cancel->Delete FileA, uploaded=FileA, removed=FileA
     *      Add FileA, Remove FileB, Cancel->Delete FileA, uploaded=FileA, removed=FileB
     *
     * @returns {Promise<any>}
     */
    cancel() : Promise<any> {
        // Files that we uploaded can be removed the server
        let ps = [];
        for (let fileEntity of this.uploaded) {
            ps.push(this.removeFromServer(fileEntity.url));
            ps.push(this.removeFromServer(fileEntity.smallThumbnailUrl));
            ps.push(this.removeFromServer(fileEntity.mediumThumbnailUrl));
        }

        return Promise.all(ps);
    }

    removeFromServer(url: string) : Promise<any> {
        // URLS are like T2/entity/filepath
        let [tenant, entityId, filename] = url.split('/');

        let resource = new UploadResource()
            .withEntityId(entityId)
            .withFile(filename)
            .withQueryParameter('bucket', 'public');

        return this.webApi.delete(resource)
            .then(httpResponseMessage => {
                this.logger.info("delete response: " + httpResponseMessage.response);
                return Promise.resolve(filename);
            })
            .catch((err) => {

                this.logger.warn(err);
            });
    }
}

const array_move = (array, from, to) => {
    const startIndex = from < 0 ? array.length + from : from;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = to < 0 ? array.length + to : to;

        const [item] = array.splice(from, 1);
        array.splice(endIndex, 0, item);
    }
};

