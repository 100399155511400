import { FrameworkConfiguration } from "aurelia-framework";
import {PLATFORM} from "aurelia-pal";

// Converters
export * from './converters/arp-age-format';
export * from './converters/arp-array-format';
export * from './converters/arp-currency-format';
export * from './converters/arp-date-format';
export * from './converters/arp-enum-format';
export * from './converters/arp-integer-format';
export * from './converters/arp-name-format';
export * from './converters/arp-person-name-format';
export * from './converters/arp-trim';
export * from './converters/arp-weight-format';
export * from './converters/arp-yesno-format';

// Attributes
export * from './attributes/arp-action-attribute';
export * from './attributes/arp-autolink-attribute';
export * from './attributes/arp-collapse-attribute';
export * from './attributes/arp-sort-attribute';
export * from './attributes/arp-table-placeholder';

// Elements
export * from './elements/arp-action-menu';
export * from './elements/arp-address';
export * from './elements/arp-number-input';
export * from './elements/arp-integer-input';
export * from './elements/arp-date-picker';
export * from './elements/arp-editable-list';
export * from "./elements/arp-editor";
export * from './elements/arp-email';
export * from './elements/arp-email-editor';
export * from './elements/arp-file-upload';
export * from './elements/arp-filter-result';
export * from './elements/arp-header-bar';
export * from './elements/arp-image';
export * from './elements/arp-multiline-stringlist';
export * from './elements/arp-multi-check';
export * from './elements/arp-multi-check-enum';
export * from './elements/arp-nav-bar';
export * from './elements/arp-phone';
export * from './elements/arp-phone-editor';
export * from './elements/arp-photo-upload';
export * from './elements/arp-route';
export * from './elements/arp-span-route';
export * from './elements/arp-select';
export * from './elements/arp-select-datasource';
export * from "./elements/arp-spinner";
export * from './elements/arp-status-indicator';
export * from './elements/arp-stringlist';
export * from './elements/arp-stringlist-editor';
export * from './elements/arp-switch';
export * from './elements/arp-tag-editor';
export * from './elements/arp-typeahead';
export * from './elements/arp-typeahead-datasource';

// Filters
export * from './filters/arp-filter-bar';
export * from './filters/arp-custom-filter';
export * from './filters/FilterCategory';
export * from './filters/DateFilter';
export * from './filters/BooleanFilter';
export * from './filters/EnumListFilter';
export * from './filters/EnumValueFilter';
export * from './filters/FilterBase';
export * from './filters/InstantFilter';
export * from './filters/InstantFilterCategory';

// Validation
export * from './validation/ArpValidationController';
export * from "./validation/ArpValidationRules";
export * from './validation/BootstrapFormRenderer';

// Views
export * from './views/arp-details-page';
export * from './reports/arp-download';
export * from './views/arp-message';
export * from './views/arp-instance-editor';
export * from './views/arp-instance-viewer';
export * from './views/arp-list-page';
export * from './views/arp-list-viewer';
export * from './views/arp-modal';
export * from './views/arp-pager';
export * from './views/arp-search';
export * from './views/arp-sort';
export * from './views/SortDirectionEnum';
export * from './views/IParentView'

// Misc
export * from './actions/Action';
export * from './actions/ActionList';
export * from './ArpEntity';
export * from "./log/ArpLogManager";
export * from "./log/ArpLogger";
export * from './ArpRepository';
export * from './ArpResource';
export * from './views/arp-scroller';
export * from './ArpViewState';
export * from './CreateModeEnum';
export * from './FileTransfer';
export * from './FuzzySearcher';
export * from './Observer';
export * from './WebApi';
export * from './UploadResource';
export * from "./ViewStateManager";
export * from "aurelia-framework";

function configure(aurelia: FrameworkConfiguration){

    aurelia.globalResources([

        // Attributes
        PLATFORM.moduleName('./attributes/arp-action-attribute'),
        PLATFORM.moduleName('./attributes/arp-autolink-attribute'),
        PLATFORM.moduleName('./attributes/arp-collapse-attribute'),
        PLATFORM.moduleName('./attributes/arp-sort-attribute'),
        PLATFORM.moduleName('./attributes/arp-table-placeholder'),

        // Converters
        PLATFORM.moduleName('./converters/arp-age-format'),
        PLATFORM.moduleName('./converters/arp-array-format'),
        PLATFORM.moduleName('./converters/arp-currency-format'),
        PLATFORM.moduleName('./converters/arp-date-format'),
        PLATFORM.moduleName('./converters/arp-enum-format'),
        PLATFORM.moduleName('./converters/arp-integer-format'),
        PLATFORM.moduleName('./converters/arp-name-format'),
        PLATFORM.moduleName('./converters/arp-person-name-format'),
        PLATFORM.moduleName('./converters/arp-trim'),
        PLATFORM.moduleName('./converters/arp-weight-format'),
        PLATFORM.moduleName('./converters/arp-yesno-format'),

        // Elements
        PLATFORM.moduleName('./elements/arp-action-menu'),
        PLATFORM.moduleName('./elements/arp-address'),
        PLATFORM.moduleName('./elements/arp-date-picker'),
        PLATFORM.moduleName('./elements/arp-editable-list'),
        PLATFORM.moduleName('./elements/arp-editor'),
        PLATFORM.moduleName('./elements/arp-email'),
        PLATFORM.moduleName('./elements/arp-email-editor'),
        PLATFORM.moduleName('./elements/arp-file-upload'),
        PLATFORM.moduleName('./elements/arp-filter-result'),
        PLATFORM.moduleName('./elements/arp-header-bar'),
        PLATFORM.moduleName('./elements/arp-image'),
        PLATFORM.moduleName('./elements/arp-integer-input'),
        PLATFORM.moduleName('./elements/arp-multiline-stringlist'),
        PLATFORM.moduleName('./elements/arp-multi-check'),
        PLATFORM.moduleName('./elements/arp-multi-check-enum'),
        PLATFORM.moduleName('./elements/arp-nav-bar'),
        PLATFORM.moduleName('./elements/arp-number-input'),
        PLATFORM.moduleName('./elements/arp-phone'),
        PLATFORM.moduleName('./elements/arp-phone-editor'),
        PLATFORM.moduleName('./elements/arp-photo-upload'),
        PLATFORM.moduleName('./elements/arp-route'),
        PLATFORM.moduleName('./elements/arp-span-route'),
        PLATFORM.moduleName('./elements/arp-spinner'),
        PLATFORM.moduleName('./elements/arp-select'),
        PLATFORM.moduleName('./elements/arp-status-indicator'),
        PLATFORM.moduleName('./elements/arp-stringlist'),
        PLATFORM.moduleName('./elements/arp-stringlist-editor'),
        PLATFORM.moduleName('./elements/arp-switch'),
        PLATFORM.moduleName('./elements/arp-tag-editor'),
        PLATFORM.moduleName('./elements/arp-typeahead'),

        // Views (need because of useView() annotations
        PLATFORM.moduleName('./views/arp-details-page'),
        PLATFORM.moduleName('./reports/arp-download'),
        PLATFORM.moduleName('./views/arp-message'),
        PLATFORM.moduleName('./filters/arp-filter-bar'),
        PLATFORM.moduleName('./filters/arp-custom-filter'),
        PLATFORM.moduleName('./views/arp-list-page'),
        PLATFORM.moduleName('./views/arp-modal'),
        PLATFORM.moduleName('./views/arp-pager'),
        PLATFORM.moduleName('./views/arp-search'),
        PLATFORM.moduleName('./views/arp-sort')
    ]);
}

export {
    configure
};
