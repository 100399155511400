import EXIF from 'exif-js/exif';
import {ArpLogger} from "./log/ArpLogger";
import {ArpLogManager} from "./log/ArpLogManager";

const logger: ArpLogger = ArpLogManager.getLogger("arp-image");

/**
 * Load an image in sparkie,
 *  table: 60x60
 *  details header: 90x90
 *  uploadWidget: 120x120
 *  details profile: 200x200
 *
 */
export class ArpImage {

    public readonly data: ArrayBuffer;
    public readonly mimeType: string;
    public readonly exifData: any;
    public objectUrl: string;

    protected constructor(data: ArrayBuffer, mimeType: string) {
        this.data = data;
        this.mimeType = mimeType;
        this.exifData = EXIF.readFromBinaryFile(data);

        logger.debug(`Created t=${this.mimeType}, w=${this.exifWidth}, h=${this.exifHeight}, o=${this.exifOrientation}`)
    }

    static loadFile(file: File) : Promise<ArpImage> {

        let fileReader = new FileReader();

        let promise = new Promise<ArpImage>((resolve, reject) => {

            fileReader.onload = (e: Event) => {

                let image = new ArpImage(fileReader.result as ArrayBuffer, ArpImage.getMimeType(file.name));
                resolve(image);
            };

            fileReader.onerror = () => {
                reject();
            };
        });

        fileReader.readAsArrayBuffer(file);

        return promise;
    }

    static loadUrl(url: string, crossOrigin?: string) : Promise<ArpImage> {
        let xhr = new XMLHttpRequest();
        let promise = new Promise<ArpImage>((resolve, reject) => {
            xhr.onload = (e: Event) => {
                //let arrayBufferView = new Uint8Array(xhr.response);

                if (xhr.status === 200) {
                    // TODO: Handle other extensions?  png?
                    let image = new ArpImage(xhr.response, ArpImage.getMimeType(url));
                    resolve(image);
                } else {
                    reject();
                }
            };

            xhr.onerror = () => {
                reject();
            };
        });

        xhr.responseType = "arraybuffer";
        xhr.open("GET", url, true);
        xhr.send(null);

        return promise;
    }

    static getMimeType(name: string) : string {
        if (name.endsWith('.jpg') || name.endsWith('.jpeg')) {
            return 'image/jpg';
        } else if (name.startsWith('data:image/jpeg') || name.startsWith('data:image/jpg')) {
            return 'image/jpg';
        } else {
            return 'image/png';
        }
    }

    send(url: string, contentType: string) : Promise<void> {
        let xhr = new XMLHttpRequest();

        xhr.open("put", url, true);
        xhr.setRequestHeader("Accept", "application/json");
//        xhr.setRequestHeader("Cache-Control", "no-cache");
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.setRequestHeader("Content-type", contentType);

        let promise = new Promise<void>((resolve, reject) => {
            xhr.onreadystatechange = () => {
                if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
                    resolve();
                }
            };

            xhr.onerror = () => {
                reject();
            };
        });

        xhr.send(new DataView(this.data));

        return promise;
    }

    createThumbnail(width: number, height: number) : Promise<ArpImage> {
        let promise = new Promise<ArpImage>((resolve, reject) => {
            let img = new Image();   // Create new img element

            // TODO: Is this load async or sync?
            img.onload = () => {

                let canvas: HTMLCanvasElement = document.createElement("canvas");

                if (width === 0) { width = img.width; }
                if (height === 0) { height = img.height; }
                let resizeInfo = this.resize(img.width, img.height, width, height);
                canvas.width = resizeInfo.trgWidth;
                canvas.height = resizeInfo.trgHeight;

                let ctx = canvas.getContext("2d");

                // Not intuitive, but the context transformation must occur before drawing the images
                // DID THE BROWSER ROTATE THIS IMAGE WHEN IT LOADED INTO THE ELEMENT?  IF SO WE DON"T NEED TO ROTATE
                if (img.width === this.exifWidth && img.height === this.exifHeight) {
                    this.rotate(canvas, ctx);
                }

                ctx.drawImage(
                    img,
                    resizeInfo.srcX,
                    resizeInfo.srcY,
                    resizeInfo.srcWidth,
                    resizeInfo.srcHeight,
                    resizeInfo.trgX,
                    resizeInfo.trgY,
                    resizeInfo.trgWidth,
                    resizeInfo.trgHeight
                );

                this.toBlob(canvas, (blob: Blob) => {
                    this.revokeObjectURL();

                    let fileReader = new FileReader();
                    fileReader.onload = () => {
                        let image = new ArpImage(fileReader.result as ArrayBuffer, this.mimeType);
                        resolve(image);
                    };
                    fileReader.readAsArrayBuffer(blob);
                });
            };

            img.src = this.createObjectURL();
        });

        return promise;
    }

    get exifOrientation() {
        /*
            0x0112	Orientation	int16u	IFD0
            1 = Horizontal (normal)
            2 = Mirror horizontal
            3 = Rotate 180
            4 = Mirror vertical
            5 = Mirror horizontal and rotate 270 CW
            6 = Rotate 90 CW
            7 = Mirror horizontal and rotate 90 CW
            8 = Rotate 270 CW
         */
        return this.exifData["Orientation"];
    }

    get exifWidth() {
        // 0xa002	ExifImageWidth	int16u:	ExifIFD	(called PixelXDimension by the EXIF spec.)
        return this.exifData["PixelXDimension"];
    }

    get exifHeight() {
        // 0xa003	ExifImageHeight	int16u:	ExifIFD	(called PixelYDimension by the EXIF spec.)
        return this.exifData["PixelYDimension"];
    }

    /**
     * Creates a Blob object representing the image contained in the canvas; this file may be cached on the disk
     * or stored in memory at the discretion of the user agent. If type is not specified, the image type
     * is image/png. The created image is in a resolution of 96dpi.
     *
     * @param canvas
     * @param callback
     */
    toBlob(canvas: HTMLCanvasElement, callback: (result: Blob | null) => void) {

        if (canvas.toBlob) {
            canvas.toBlob(callback);
        } else {
            // This polyfill isn't needed once Safari gets off their ass and implements canvas.toBlob()
            let foo = canvas.toDataURL().split(',')[1];
            let binStr: string = atob(foo);
            let len = binStr.length;
            let arr = new Uint8Array(len);

            for (let i = 0; i < len; i++ ) {
                arr[i] = binStr.charCodeAt(i);
            }

            let blob =  new Blob( [arr], {type: 'image/png'} );

            callback(blob);
        }
    }

    toDataUrl() : string {
        let foo = new Uint8Array(this.data);
        let bar = foo.reduce((data, byte) => data + String.fromCharCode(byte), '');
        let base64 = `data:${this.mimeType};base64,` + btoa(bar);

        return base64;
    }

    rotate(canvas: HTMLCanvasElement, ctx) {
        let orientation = this.exifOrientation;

        let width = canvas.width;
        let height = canvas.height;
        let styleWidth = canvas.style.width;
        let styleHeight = canvas.style.height;

        if (orientation > 4) {
            // noinspection JSSuspiciousNameCombination
            canvas.width = height;
            // noinspection JSSuspiciousNameCombination
            canvas.height = width;
            // noinspection JSSuspiciousNameCombination
            canvas.style.width = styleHeight;
            // noinspection JSSuspiciousNameCombination
            canvas.style.height = styleWidth;
        }

        switch (orientation) {
            case 2:
                // horizontal flip
                ctx.translate(width, 0);
                ctx.scale(-1, 1);
                break;
            case 3:
                // 180° rotate left
                ctx.translate(width, height);
                ctx.rotate(Math.PI);
                break;
            case 4:
                // vertical flip
                ctx.translate(0, height);
                ctx.scale(1, -1);
                break;
            case 5:
                // vertical flip + 90 rotate right
                ctx.rotate(0.5 * Math.PI);
                ctx.scale(1, -1);
                break;
            case 6:
                // 90° rotate right
                ctx.rotate(0.5 * Math.PI);
                ctx.translate(0, -height);
                break;
            case 7:
                // horizontal flip + 90 rotate right
                ctx.rotate(0.5 * Math.PI);
                ctx.translate(width, -height);
                ctx.scale(-1, 1);
                break;
            case 8:
                // 90° rotate left
                ctx.rotate(-0.5 * Math.PI);
                ctx.translate(-width, 0);
                break;
        }
    }

    createObjectURL() : string {
        let blob = new Blob([this.data]);
        if (window.URL && window.URL.createObjectURL ) {
            this.objectUrl = window.URL.createObjectURL(blob);
        }

        return this.objectUrl
    }

    revokeObjectURL() {
        if (this.objectUrl && window.URL && window.URL.revokeObjectURL ) {
            window.URL.revokeObjectURL(this.objectUrl);
            this.objectUrl = null;
        }
    }

    resize(currentWidth: number, currentHeight: number, newWidth: number, newHeight: number) {
        let info,
            srcRatio,
            trgRatio;
        info = {
            optWidth: newWidth,
            optHeight: newHeight,
            srcX: 0,
            srcY: 0,
            srcWidth: currentWidth,
            srcHeight: currentHeight,
            trgX: 0,
            trgY: 0,
        };

        srcRatio = currentWidth / currentHeight;
        trgRatio = info.optWidth / info.optHeight;

        if (currentHeight < info.optHeight || currentWidth < info.optWidth) {
            info.trgHeight = info.srcHeight;
            info.trgWidth = info.srcWidth;
        } else {
            if (srcRatio > trgRatio) {
                info.srcHeight = currentHeight;
                info.srcWidth = info.srcHeight * trgRatio;
            } else {
                info.srcWidth = currentWidth;
                info.srcHeight = info.srcWidth / trgRatio;
            }
            info.trgWidth = info.optWidth;
            info.trgHeight = info.optHeight;
        }

        info.srcX = (currentWidth - info.srcWidth) / 2;
        info.srcY = (currentHeight - info.srcHeight) / 2;

        return info;
    }
}
