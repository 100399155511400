
/**
 * Clear the contents of an array in a manner that doesn't break observation callbacks.
 *
 * @param anArray
 */
export function safeClearArray(anArray: any[]) {
    if (anArray.length > 0) {
        anArray.splice(0, anArray.length);
    }
}

/**
 * Adds a value to an array only if it doesn't already exist, emulating a Set<T>.
 *
 * @param anArray
 * @param value
 */
export function addIfMissing<T>(anArray: T[], value: T) {
    if (!anArray.includes(value)) {
        anArray.push(value);
    }
}